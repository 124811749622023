import React, { useState } from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { colors, medWrapper } from "../../styles/helpers"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Lightbox from "./Lightbox"

const settings = {
  fade: false,
  draggable: true,
  infinite: true,
  speed: 250,
  centerMode: true,
  centerPadding: "75px",
  dots: false,
  arrows: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        centerPadding: "75px",
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        centerPadding: "0px",
        slidesToShow: 1,
      },
    },
  ],
}

const SliderLightbox = ({ data }) => {
  const [lightboxActive, setLightboxActive] = useState(false)
  const [activeSlide, setActiveSlide] = useState(0)
  return (
    <StyledSection>
      <div className="wrapper">
        <Slider className="gallery" {...settings}>
          {data.sliderSlides.map((slide, index) => {
            const imgDisplay = getImage(
              slide.image.localFile.childImageSharp.gatsbyImageData
            )
            const imgAlt = slide.image.altText
            return (
              <Slide
                key={index}
                onClick={() => {
                  setLightboxActive(true)
                  setActiveSlide(index)
                  console.log("CLICKED: ", index)
                }}
              >
                <div className="image-wrapper">
                  <GatsbyImage
                    image={imgDisplay}
                    alt={imgAlt}
                    layout="fullWidth"
                    formats={["auto", "webp", "avif"]}
                  />
                </div>
              </Slide>
            )
          })}
        </Slider>
      </div>
      {lightboxActive && (
        <Lightbox
          slides={data.sliderSlides}
          start={activeSlide}
          setLightboxActive={setLightboxActive}
        />
      )}
    </StyledSection>
  )
}

const StyledSection = styled.div`
  position: relative;
  .wrapper {
    ${medWrapper};

    @media (min-width: 768px) {
      padding: 2rem 5rem;
    }

    @media (min-width: 1025px) {
      max-width: 114.2rem;
    }
  }

  .gallery {
    width: 100%;

    .slick-slide {
      padding: 0 0.5rem;
    }

    .slick-arrow {
      top: 50%;
      width: 4rem;
      height: 4rem;
      z-index: 1000;
      background-color: ${colors.colorAccent};

      @media (min-width: 768px) {
        width: 4rem;
        height: 4rem;
      }

      @media (min-width: 1025px) {
        width: 3rem;
        height: 3rem;
      }

      &::before {
        font-family: "FontAwesome" !important;
      }

      &.slick-prev {
        left: -4rem;

        @media (min-width: 768px) {
          left: -4.5rem;
        }
        @media (min-width: 1025px) {
          left: -5rem;
        }

        &::before {
          content: "\f053";
        }
      }

      &.slick-next {
        right: -4rem;

        @media (min-width: 768px) {
          right: -4.5rem;
        }

        @media (min-width: 1025px) {
          right: -5rem;
        }

        &::before {
          content: "\f054";
        }
      }
    }
  }
`

const Slide = styled.div`
  position: relative;
  flex-wrap: wrap;
  justify-content: center;
  height: 25rem;
  cursor: zoom-in;
  overflow: hidden;

  .image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .gatsby-image-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100%);
      height: 100%;

      img {
        width: 100% !important;
      }
    }
  }
`

export default SliderLightbox
