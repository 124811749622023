import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import dotGraphic from "../../images/dot-graphic-orange.png"

const Hero = ({ data }) => {
  const imgDisplay = getImage(
    data.heroImage.localFile.childImageSharp.gatsbyImageData
  )
  const imgAlt = data.heroImage.altText
  return (
    <StyledSection>
      <div className="wrapper">
        <div className="hero-image">
          <GatsbyImage
            image={imgDisplay}
            alt={imgAlt}
            layout="fullWidth"
            formats={["auto", "webp", "avif"]}
          />
        </div>
      </div>
      <div className="dot-graphic">
        <img src={dotGraphic} alt="" />
      </div>
    </StyledSection>
  )
}

const StyledSection = styled.div`
  position: relative;
  width: 100%;
  height: 40rem;
  margin-top: -5rem;
  z-index: 10;

  @media (min-width: 768px) {
    height: 50rem;
    margin-top: -7.5rem;
  }

  @media (min-width: 1025px) {
    height: 62.8rem;
    margin-top: -10rem;
  }

  .dot-graphic {
    position: absolute;
    bottom: -15rem;
    right: 0;
    width: 21.3rem;
    z-index: 1;
  }

  .hero-image {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 100%;
    max-width: 126.4rem;

    @media (min-width: 768px) {
      right: 5rem;
      left: 5rem;
    }

    .gatsby-image-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100%);
      height: 100%;

      img {
        width: 100% !important;
      }
    }
  }
`

export default Hero
