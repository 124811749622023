import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { colors } from "../../styles/helpers"

const settings = {
  fade: false,
  draggable: true,
  infinite: true,
  speed: 250,
  centerMode: true,
  centerPadding: "0",
  arrows: true,
  dots: false,
}

const Lightbox = ({ slides, start, setLightboxActive }) => {
  return (
    <StyledLightbox>
      <div className="wrapper">
        <Slider className="lightbox-slider" initialSlide={start} {...settings}>
          {slides.map((slide, index) => {
            const imgDisplay = getImage(
              slide.image.localFile.childImageSharp.gatsbyImageData
            )
            const imgAlt = slide.image.altText
            return (
              <div key={index}>
                <div className="image-wrapper">
                  <GatsbyImage
                    image={imgDisplay}
                    alt={imgAlt}
                    layout="fullWidth"
                    formats={["auto", "webp", "avif"]}
                  />
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
      <div className="closeBtn">
        <button onClick={() => setLightboxActive(false)}>&#9747;</button>
      </div>
    </StyledLightbox>
  )
}

const StyledLightbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10000;

  .closeBtn {
    position: absolute;
    top: 5rem;
    right: 5rem;

    button {
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
      cursor: pointer;
      transition: all ease-in 0.3s;
      background-color: ${colors.colorTertiary};
      border: 0.25rem solid ${colors.colorTertiary};
      color: ${colors.white};
      font-weight: bold;

      &:hover {
        background-color: ${colors.colorPrimary};
        border: 0.25rem solid ${colors.colorPrimary};
        color: ${colors.white};
      }
    }
  }

  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 80rem;
    margin: auto;
  }

  .lightbox-slider {
    width: 100%;

    .slick-track {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .slick-arrow {
      top: 50%;
      width: 3rem;
      height: 3rem;
      z-index: 1000;
      background-color: ${colors.colorTertiary};

      &::before {
        font-family: "FontAwesome" !important;
      }

      &.slick-prev {
        left: -2rem;

        @media (min-width: 768px) {
          left: -5rem;
        }

        &::before {
          content: "\f053";
        }
      }

      &.slick-next {
        right: -2rem;

        @media (min-width: 768px) {
          right: -5rem;
        }

        &::before {
          content: "\f054";
        }
      }
    }

    .image-wrapper {
      max-height: 95vh;
    }
  }
`

export default Lightbox
