import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import {
  B1DarkGrey,
  Btn1One,
  Btn1Two,
  colors,
  standardWrapper,
} from "../../styles/helpers"
import greyStripe from "../../images/grey-stripe.png"

const MoreInformationLinks = ({ data }) => {
  return (
    <StyledSection>
      <div className="wrapper">
        <div className="title">
          <h2>{data.moreInformationLinksTitle}</h2>
        </div>
        <div className="links">
          {data.moreInformationLinksButtons.map((link, index) => {
            return (
              <div className="link">
                {link.linkType === "external" ? (
                  <a
                    key={index}
                    target="_blank"
                    rel="noreferrer"
                    href={link.url}
                  >
                    {link.buttonText}
                  </a>
                ) : link.linkType === "internal" ? (
                  <Link to={`${link.page.uri}`} key={index}>
                    {link.buttonText}
                  </Link>
                ) : link.linkType === "section" ? (
                  <Link to={`${link.page.uri}${link.sectionId}`} key={index}>
                    {link.buttonText}
                  </Link>
                ) : null}
              </div>
            )
          })}
        </div>
      </div>
      <div className="bg-color__graphic" />
    </StyledSection>
  )
}

const StyledSection = styled.section`
  position: relative;
  padding: 4rem 0 5rem;
  background-color: ${colors.colorShad};

  .bg-color__graphic {
    position: absolute;
    top: -5rem;
    left: -5%;
    padding-bottom: 15rem;
    width: 110%;
    background-image: url(${greyStripe});
    background-size: 100% 100%;
    background-repeat: repeat-x;
    background-position: bottom center;
    z-index: -1;
  }

  .wrapper {
    ${standardWrapper};
  }

  .title {
    width: 100%;
    text-align: center;

    h2 {
      ${B1DarkGrey};
    }
  }

  .links {
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;

    .link {
      margin-bottom: 2rem;

      @media (min-width: 768px) {
        margin: auto 0;
        &:nth-of-type(2) {
          margin: auto 0.5rem;
        }
      }

      @media (min-width: 1025px) {
        &:nth-of-type(2) {
          margin: auto 1rem;
        }
      }

      a:nth-of-type(2n + 1) {
        ${Btn1Two};
      }

      a:nth-of-type(2n + 2) {
        ${Btn1One};
      }
    }
  }
`

export default MoreInformationLinks
